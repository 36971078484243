import React from 'react'
import './Header.css'

export default function Header() {
  return (
    <div className='title'>
        <a href='/'>popcornfamine</a>
    </div>
  )
}
