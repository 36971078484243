import React from 'react'
import "./ProjectContent.css"

export default function CalendarContent({project}) {



  return (
    <div className='projects-container'>
      <project.default/>
    </div>
      
  )
}
