import React from 'react'
import "./css/projects.css"

export const metadata = {
    categories: ["Text"],
    title: "Audio Plug-in (WIP)",
    date: "Aug/12/2024"
  }

export default function project_3() {
  return (
    <div>
        <h1>DSP Plugin (WIP)</h1>
        <p>Update coming soon...</p>
    </div>
  )
}
